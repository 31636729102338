.our-consumers-review {
    width: 160px;
    align-self: stretch;
    position: relative;
    letter-spacing: 0.04em;
    line-height: 150%;
    text-transform: capitalize;
    font-weight: 500;
}

.star-icons {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 18px;
}

.kk,
.review-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.kk {
    flex-direction: row;
    padding: 0 var(--padding-base);
    gap: var(--gap-7xs-5);
}

.review-container {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-11xs);
}

.header-content {
    width: 144px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.projects {
    text-decoration: underline;
}

.projects-with-human-container {
    margin: 0;
    font-size: xx-large !important;
    text-align: center;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    letter-spacing: -0.04em;
    line-height: 110%;
    text-transform: capitalize;
    font-weight: 500;
    font-family: inherit;
}

.project-subtitle {
    text-align: center;
    width: 70%;
    margin: auto;
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-lg);
    /* font-size: clamp(1.2rem, 12px , 2.5rem); */
    letter-spacing: 0.02em;
    line-height: 140%;
    font-family: var(--font-inter);
    white-space: pre-wrap;
}

.project-description {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
    text-align: justify;

    margin-bottom: 50px;
}

.header,
.header-wrapper1 {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.header-wrapper1 {
    /* width: 848px; */
    margin-top: 56px;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-11xs);
}

.header {
    /* width: 1186px; */
    flex-direction: row;
    justify-content: center;
    padding: 0 var(--padding-xl);
    box-sizing: border-box;
    flex-shrink: 0;
}

.get-quote1 {
    position: relative;
    font-size: var(--font-size-sm);
    letter-spacing: 0.02em;
    line-height: 150%;
    display: inline-block;
    font-family: var(--font-satoshi-variable);
    /* color: var(--white); */
    color: #ffffff;
    text-align: left;
    min-width: 125px;
}

.icon-relume17 {
    height: 21.3px;
    width: 21.3px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 21px;
}

.button4,
.quote-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

.quote-buttons button  {
    border: 1px solid #000000;

}

.quote-buttons button b {
    color: #000000;

}

.button4 {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xs) var(--padding-xl);
    /* background-color: var(--color-brand-dark-green); */
    box-shadow: var(--shadow-medium);
    border-radius: var(--br-7xs);
    gap: var(--gap-xs);
    white-space: nowrap;
    color: #000000;
    border: 1 px solid #000000;
}

.button4.active {
    background-color:orange; /* set background color to blue when active */
    color: white; /* set text color to white */
  }

.button4:hover , button b:hover {
    background-color: var(--color-blue);
    color: #fff !important;
}

.quote-buttons {

    /* height: 45px; */
    gap: var(--gap-base);
    width: 90%;
    overflow: auto;
}

.button-container,
.header-parent,
.project-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.project-selection {
    /* width: 1236px; */
    width: 88%;
    overflow: hidden;
    /* padding: 0 var(--padding-xl) 0 0; */
    box-sizing: border-box;
    gap: var(--gap-13xl);
    max-width: 100%;
    font-size: var(--font-size-11xl-4);
    color: var(--white);
}

.button-container,
.header-parent {
    flex-shrink: 0;
}

.button-container {
    /* width: 1384px; */
    gap: var(--gap-23xl);
    max-width: 111%;
    font-size: var(--font-size-sm);
}

.header-parent {
    align-self: stretch;
    gap: var(--gap-65xl);
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-xs);
    color: var(--text-primary);
    font-family: var(--font-satoshi-variable);
}

@media screen and (max-width: 1050px) {
    .projects-with-human-container {
        font-size: var(--font-size-27xl);
        line-height: 51px;
    }

    .header {
        gap: var(--gap-158xl);
    }
}

@media screen and (max-width: 750px) {
    .project-selection {
        gap: var(--gap-base);
    }

    .button-container {
        gap: var(--gap-2xl);
    }

    .header-parent {
        gap: var(--gap-65xl);
    }
}

@media screen and (max-width: 450px) {
    .projects-with-human-container {
        font-size: var(--font-size-16xl);
        line-height: 38px;
    }

    .header {
        gap: var(--gap-69xl);
    }

    .header-parent {
        gap: var(--gap-2xl);
    }

    .project-subtitle {
        width: 90%;
        font-size: var(--font-size-sm);
    }
}
.heading,
.text {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: inherit;
}

.heading {
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
}

.text {
  width: 95%;
  font-size: var(--font-size-lg);
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
  font-weight: 400;
  white-space: pre-wrap;
  font-family: 'Satoshi Variable';
}

.headingParent {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}

.buttonParent,
.frameWrapper {
  margin: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  /* box-sizing: border-box; */
  gap: 10px;
  width: 90%;
  overflow: auto;
  /* flex-wrap: wrap; */
}

.buttonParent button {
  padding: 10px 30px;
  border: 1px solid black;
  border-radius: 5px;
  font: bolder;
  color: #000000;
  /* min-width: 112px; */
  min-height: 21px;
  cursor: pointer;
  text-wrap: nowrap;
}

.buttonParent button:hover {
  background-color: #2a632a  !important;
  color: #ffffff;
}

.frameWrapper {
  width: 1216px;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  max-width: 100%;
}

.placeholderImage2 {
  align-self: stretch;
  width: 416px;
  position: relative;
  border-radius: var(--br-xs);
  max-height: 100%;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 416px;
  max-width: 100%;
}

.placeholderImage2Parent {
  /* width: 100%; */
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  max-width: 100%;
}

.placeholderImage2Parent img {
  width: 350px;
  height: auto;
}

.text1 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: small;
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}

.textWrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: justify;
}

.frameContainer {
  padding: 0 10px;
  flex-direction: column;
  box-sizing: border-box;
  gap: var(--gap-lg);
  max-width: 100%;
  font-size: var(--font-size-lg);
  color: var(--color-neutral-black);
  margin-bottom: 20px;
}

.desktop1280x800Inner,
.frameContainer,
.frameGroup,
.frameParent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.frameGroup {
  /* width: 1384px; */
  flex-direction: column;
  gap: var(--gap-35xl);
  max-width: 111%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-mini-2);
  color: var(--color-saddlebrown);
}

.desktop1280x800Inner,
.frameParent {
  max-width: 100%;
}

.frameParent {
  width: 1280px;
  flex-direction: column;
  gap: var(--gap-44xl);
}

.desktop1280x800Inner {
  flex-direction: row;
  margin: auto;
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-neutral-black);
  font-family: var(--font-inter);
}

/* Responsive Styles */

/* Tablet Devices (max-width: 1100px) */
@media screen and (max-width: 1100px) {

  .desktop1280x800Inner {
    padding-bottom: var(--padding-77xl);
  }

  .placeholderImage2 {
    overflow: hidden;
    width: 300px;
    /* Adjust size for tablet */
    min-height: 300px;
    /* Adjust size for tablet */
  }
}

/* Small Tablets and Large Phones (max-width: 750px) */
@media screen and (max-width: 750px) {

  .placeholderImage2Parent::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Webkit browsers */
  }

  .placeholderImage2Parent {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    display: flex;
    flex-direction: row;
    /* Arrange images horizontally */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory;
    /* Ensure smooth snapping between images */
    gap: var(--gap-base);
    /* Add space between images */
    padding: var(--gap-sm);
    /* Add padding around the images */
  }

  .text {
    font-size: 14px;
    text-align: justify;
  }

  .heading {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }

  .frameContainer {
    padding-right: var(--padding-3xl);
  }

  .frameGroup {
    gap: var(--gap-8xl);
  }

  .frameParent {
    gap: var(--gap-12xl);
  }

  .placeholderImage2 {
    width: 90%;
    /* Adjust the width of each image */
    height: auto;
    /* Ensure image maintains its aspect ratio */
    scroll-snap-align: center;
    /* Snap images to the center of the viewport when scrolling */
    flex-shrink: 0;
    /* Prevent images from shrinking */
  }
}

/* Mobile Devices (max-width: 450px) */
@media screen and (max-width: 450px) {
  .placeholderImage2Parent::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Webkit browsers */
  }

  .placeholderImage2Parent {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    flex-direction: column;
    /* Stack images in a column */
    justify-content: center;
    /* Center images vertically */
    align-items: center;
    /* Center images horizontally */
    height: auto;
    /* Allow container to expand beyond viewport */
    min-height: 100vh;
    /* Ensure container is at least the height of the viewport */
    padding: var(--gap-sm) 0;
    /* Add padding to prevent content from touching the edges */
  }

  .heading {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }

  .frameParent {
    gap: var(--gap-base);
  }

  .desktop1280x800Inner {
    padding-bottom: var(--padding-43xl);
  }

  .placeholderImage2 {
    width: 70%;
    /* Set a smaller width for the images */
    max-width: 80%;
    height: auto;
    /* Ensure image maintains its aspect ratio */
    min-height: auto;
    /* Remove fixed height */
    margin-bottom: var(--gap-sm);
    /* Add some space between images */
  }
}
.project-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: #f7f8fa;
    padding: 20px;
}

.project-details-card {
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    animation: fadeIn 0.5s ease-in-out;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 1;
    position: relative;

}

.project-details-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent black overlay */
    z-index: -1; /* Ensures the overlay is behind the card content */
    border-radius: 8px; /* Match the border radius of the card */
}



@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.back-button {
    background-color: orange;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
    margin-bottom: 20px;
}

.back-button:hover {
    background-color: #057ec9;
}

.project-header {
    text-align: center;
    margin-bottom: 20px;
}

.project-title {
    font-size: 24px;
    font-weight: bold;
    color: #ece3e3;
    margin-bottom: 20px;
}

.project-image {
    max-width: 100%;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
}

.project-image:hover {
    transform: scale(1.05);
}

.project-content {
    color: #ded8d8;
    font-size: 16px;
    line-height: 1.6;
}

.project-description-details {
    margin-bottom: 20px;
    color: #ffffff;
}

.project-details-info ul {
    list-style: none;
    padding: 0;
}

.project-details-info li {
    margin-bottom: 10px;
    font-size: 16px;
}

.detail-label {
    font-weight: bold;
    color: #f4eded;
}

.no-details {
    text-align: center;
    font-size: 18px;
    color: #c8b8b8;
}

@media (max-width: 600px) {
    .project-details-card {
        padding: 15px;
    }

    .project-title {
        font-size: 20px;
    }

    .back-button {
        font-size: 12px;
    }
}

.hero-section,
.main {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.hero-section {
  /* height: 800px; */
  flex: 1;
  background-color: var(--white);
  flex-direction: column;
  /* padding: 0 0 724px var(--padding-13xl); */
  gap: 110px;
  padding-bottom: 30px;
}

.main {
  align-self: stretch;
  flex-direction: row;
  /* padding: 0 0 1179px; */
  flex-shrink: 0;
}

.what-we-did {
  margin: 0 0 0 50px;
  padding: 10px;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 84px;
  font-weight: 500;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 50px;
}

.what-we-did-a-showcase-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 50px;
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-57xl);
  color: var(--text-primary);
  font-family: var(--font-satoshi-variable);
}

.placeholder-image-icon {
  display: none;
}

.placeholder-image-icon,
.placeholder-image-icon1 {
  align-self: stretch;
  width: 416px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  min-height: 340px;
  max-width: 100%;
}

.how-we-execute {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}

.how-we-execute-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}

.at-our-interior {
  width: 781px;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 140%;
  display: inline-block;
  max-width: 100%;
}

.process,
.process-steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}

.process {
  align-self: stretch;
  align-items: flex-start;

  box-sizing: border-box;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--text-primary);
  font-family: var(--font-inter);
}

.process-steps {
  width: 1151.3px;

  flex-wrap: wrap;
  align-content: center;
  gap: var(--gap-13xl);
}

.execution-cards,
.project-page {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}

.execution-cards {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 var(--padding-37xl) 127.1px;
  max-width: 100%;
}

.project-page {
  width: 100%;
  /* height: 7737px; */
  /* position: relative; */
  background-color: var(--white);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 0 0 715.7px; */
  gap: var(--gap-xs);
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--text-primary);
  font-family: var(--font-satoshi-variable);
}

@media screen and (max-width: 1200px) {

  .hero-section,
  .main {
    /* padding-bottom: 306px; */
    box-sizing: border-box;
  }

  
}

@media screen and (max-width: 1050px) {
  .what-we-did {
    font-size: var(--font-size-42xl);
    line-height: 67px;
  }

  .what-we-did-a-showcase-wrapper {
    /* padding-left: var(--padding-7xl);
    padding-right: var(--padding-7xl); */
    /* padding-bottom: 609px; */
    box-sizing: border-box;
  }

  .how-we-execute {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
}

@media screen and (max-width: 750px) {

  .hero-section,
  .main {
    box-sizing: border-box;
  }

  .hero-section {
    height: auto;
    gap: 55px;
    padding-bottom: 25px;
  }

  

  .what-we-did-a-showcase-wrapper {
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .process-steps {
    gap: var(--gap-base);
  }

  .execution-cards {
    padding-left: var(--padding-9xl);
    padding-right: var(--padding-9xl);
    box-sizing: border-box;
  }

  .project-page {
    height: auto;
  }

  .how-we-execute-wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 450px) {
  .hero-section {
    gap: 27px;
  }

  .what-we-did {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }

  .how-we-execute {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }

  .execution-cards {
    padding-bottom: 83px;
    box-sizing: border-box;
  }

  .what-we-did {
    margin: 0 0 0 10px;
    font-size: var(--font-size-7xl);
  }

}
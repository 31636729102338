.heading,
.text {
  position: relative;
}

.heading {
  width: 480px;
  line-height: 42px;
  display: inline-block;
  max-width: 100%;
}

.text {
  align-self: stretch;
  font-size: var(--font-size-mid-4);
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
}

.headingParent {
  width: 507px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mid-4);
  max-width: 100%;
}

.vectorIcon {
  height: 16.2px;
  width: 17.1px;
  position: relative;
  min-height: 16px;
}

.stars {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs-4);
  flex-shrink: 0;
}

.quote {
  margin: 0;
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  /* min-height: 96px; */
  padding: 10px 0;
  flex-shrink: 0;
}

.avatarImageIcon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
  background-color: white;
}

.clientPosition,
.clientTestimonial {
  position: relative;
  text-transform: capitalize;
}

.clientTestimonial {
  /* width: 121px; */
  letter-spacing: -0.02em;
  line-height: 140%;
  display: inline-block;
  min-width: 121px;
}

.clientPosition {
  align-self: stretch;
  font-size: var(--font-size-base-4);
  letter-spacing: 0.01em;
  line-height: 22px;
}

.avatarContent {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 114px;
}

.divider {
  height: 53.1px;
  width: 0.9px;
  position: relative;
  border-right: 0.9px solid var(--background-color-primary);
  box-sizing: border-box;
}

.iconLibrary {
  height: 45px;
  width: 100px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
border-radius: 12px;
}

.hitchHike {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 150%;
  font-weight: 700;
  font-family: inherit;
}

.content1,
.iconGoogle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconGoogle {
  overflow: hidden;
  justify-content: center;
  gap: var(--gap-5xs);
}

.content1 {
  flex: 1;
  justify-content: flex-end;
  opacity: 0.7;
  min-width: 111px;
  text-align: left;
  font-size: var(--font-size-9xl);
  font-family: var(--font-montserrat);
}

.avatar,
.avatarContent1,
.container {
  display: flex;
  justify-content: flex-start;
}

.avatar {
  /* width: 446.4px; */
  flex-direction: row;
  align-items: center;
  gap: var(--gap-mid-1);
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
}

.avatarContent1,
.container {
  flex-direction: column;
  align-items: flex-start;
}

.container {
  flex: 1;
  overflow: hidden;
  gap: var(--gap-8xl-4);
  min-width: 361px;
  max-width: 100%;
}

.avatarContent1 {
  /* width: 176px; */
}

.divider1 {
  height: 53.1px;
  width: 0.9px;
  position: relative;
  border-right: 0.9px solid var(--background-color-primary);
  box-sizing: border-box;
}

.library {
  font-size: var(--font-size-xs);
}

.archiveLibrary {
  position: relative;
  line-height: 150%;
}

.avatar1,
.content,
.content2 {
  display: flex;
  flex-direction: row;
}

.content2 {
  align-items: center;
  justify-content: flex-end;
  opacity: 0.7;
  text-align: left;
  font-size: var(--font-size-9xl);
  font-family: var(--font-montserrat);
}

.avatar1,
.content {
  max-width: 100%;
}

.avatar1 {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mid-1);
  flex-shrink: 0;
  text-align: center;
}

.content {
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: var(--gap-43xl-9);
}

.dot,
.dot1 {
  height: 6.9px;
  width: 6.9px;
  position: relative;
  border-radius: 50%;
  background-color: var(--background-color-primary);
}

.dot1 {
  background-color: var(--color-lightgray);
}

.sliderDots,
.sliderDotsContainer {
  display: flex;
  align-items: flex-start;
}

.sliderDots {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-6xs-8);
  flex-shrink: 0;
}

.sliderDotsContainer {
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 var(--padding-base-5);
}

.frameChild {
  object-fit: contain;
}

.frameChild,
.frameItem {
  align-self: stretch;
  width: 46px;
  position: relative;
  max-height: 100%;
  min-height: 15px;
}

.content3,
.vectorParent {
  display: flex;
  flex-direction: row;
}

.vectorParent {
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-7xs-3);
  gap: var(--gap-lg);
  flex-shrink: 0;
}

.content3 {
  align-self: stretch;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.contentParent,
.testimonial11,
.testimonial11Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.contentParent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-22xl-2);
  font-size: var(--font-size-mid-4);
}

.testimonial11,
.testimonial11Wrapper {
  box-sizing: border-box;
}

.testimonial11 {
  flex: 1;
  border-radius: var(--br-5xl);
  overflow: hidden;
  flex-direction: column;
  /* padding: var(--padding-37xl) var(--padding-11xl); */
  gap: var(--gap-49xl-5);
  background-image: url(/public/images/testimonial--11@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 36px 30px;
}

.testimonial11Wrapper {
  align-self: stretch;
  flex-direction: row;
  /* padding: 0 var(--padding-4xl) var(--padding-174xl-6); */
  padding: 60px 10px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-15xl-7);
  color: var(--background-color-primary);
  font-family: var(--font-inter);
}

@media screen and (max-width: 1275px) {
  .content {
    gap: var(--gap-12xl);
  }
}

@media screen and (max-width: 750px) {
  .heading {
    font-size: var(--font-size-9xl);
    line-height: 33px;
  }

  .container {
    min-width: 100%;
  }

  .divider1 {
    width: 100%;
    height: 0.9px;
  }

  .avatar1 {
    flex-wrap: wrap;
  }

  .content {
    gap: var(--gap-base);
  }

  .contentParent {
    gap: var(--gap-2xl);
  }

  .testimonial11 {
    gap: var(--gap-15xl);
    padding-top: var(--padding-17xl);
    padding-bottom: var(--padding-17xl);
    box-sizing: border-box;
  }

  .testimonial11Wrapper {
    padding-bottom: var(--padding-107xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-2xl);
    line-height: 25px;
  }

  .divider {
    width: 100%;
    height: 0.9px;
  }

  .hitchHike {
    font-size: var(--font-size-3xl);
    line-height: 34px;
  }

  .avatar,
  .content3 {
    flex-wrap: wrap;
  }

  .content3 {
    justify-content: center;
  }

  .testimonial11 {
    gap: var(--gap-mid);
  }

  .text {
    font-size: var(--font-size-sm);
  }

  .quote {
    font-size: var(--font-size-sm);
  }
}
.fAQ,
.faqs {
  align-self: stretch;
  position: relative;
  line-height: 47px;
}
.fAQ {
  font-size: var(--font-size-mid-4);
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-inter);
}
.accordion,
.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  flex-shrink: 0;
}
.sectionTitle {
  width: 625.2px;
  gap: var(--gap-lgi-5);
}
.accordion {
  align-self: stretch;
  border-bottom: 0.8px solid var(--color-neutral-black);
  box-sizing: border-box;
  padding: 0;
  font-size: var(--font-size-mini-7);
}
.stillHaveA {
  position: relative;
  line-height: 34px;
}
.content1,
.stillHaveA,
.text {
  align-self: stretch;
}
.text {
  position: relative;
  font-size: var(--text-regular-normal-size);
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-inter);
}
.content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  gap: var(--gap-smi);
}
.button1 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 49px;
}
.button {
  color: white;
  border-radius: var(--br-7xs);
  border: 0.8px solid var(--color-lightblue);
  background-color: rgb(48 127 179);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-lgi-5);
  font-size: var(--font-size-sm-1);
  font-family: var(--font-inter);
  cursor: pointer;
  transition: .5s ease-in-out;
}

.button:hover{
  transition: .5s ease-in-out;
  background-color: #0e80de;
  color: #fff;
}
.content,
.faq2,
.faq2Wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.content {
  width: 455.9px;
  flex-direction: column;
  gap: var(--gap-lgi-5);
  flex-shrink: 0;
  font-size: var(--font-size-7xl-1);
}
.faq2,
.faq2Wrapper {
  box-sizing: border-box;
}
.faq2 {
  flex: 1;
  background-color: var(--background-color-primary);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-33xl) var(--padding-33xl);
  gap: 30px;
}
.faq2Wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-9xl-6);
  text-align: left;
  font-size: var(--font-size-20xl-1);
  color: var(--color-neutral-black);
  font-family: var(--text-regular-normal);
}
@media screen and (max-width: 1275px) {
  .faq2 {
    padding: var(--padding-40xl) var(--padding-7xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .faqs {
    font-size: var(--font-size-12xl);
    line-height: 38px;
  }
  .faq2 {
    gap: var(--gap-14xl);
    padding-top: var(--padding-19xl);
    padding-bottom: var(--padding-19xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .faqs {
    font-size: var(--font-size-4xl);
    line-height: 28px;
  }
  .stillHaveA {
    font-size: var(--font-size-2xl);
    line-height: 27px;
  }
  .faq2 {
    gap: var(--gap-base);
  }
}

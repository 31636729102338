.question1 {
  flex: 1;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 21px;
  display: inline-block;
  max-width: calc(100% - 46px);
}
.icon {
  height: 26.1px;
  width: 26.1px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.question {
  align-self: stretch;
  border-top: 0.8px solid var(--text-primary);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 var(--padding-base-3);
  gap: var(--gap-lgi-5);
  max-width: 100%;
}
.text3 {

  position: relative;
  letter-spacing: 0.02em;
  line-height: 130%;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.accordion-item,
.answer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-lgi-5);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-sm);
}
.accordion-item {
  flex-direction: column;
  padding: 0 0 var(--padding-12xs-1);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--text-primary);
  font-family: var(--font-satoshi-variable);
}
.accordion-container{
  width: 100%;
}
.counting-continues {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 53px;
  font-weight: 500;
  font-family: inherit;
}

.counting-title{
  font-weight: bold;
}
.counter-subtitle {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: 0.02em;
  line-height: 140%;
  text-transform: capitalize;
  font-family: var(--font-inter);
}

.counter-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.counts,
.stats-content {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.counts {
  width: 696px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-7xl-1);
  font-family: var(--font-inter);
}

.stats-content {
  width: 846px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-53xl);
}

.stats-wrapper,
.stats-wrapper-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  /* padding: 0 10px; */
  max-width: 100%;
}

.stats-wrapper {
  margin: 0 10px;
  border-radius: 5px;
  flex: 1;
  box-shadow: var(--shadow-xxlarge);
  backdrop-filter: blur(400px);
  background-color: var(--color-green);
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-51xl) var(--padding-xl) var(--padding-50xl);
  /* mix-blend-mode: difference; */
}

.stats-wrapper-wrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 var(--padding-26xl);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-25xl);
  color: var(--text-primary);
  font-family: var(--font-satoshi-variable);
}

@media screen and (max-width: 1050px) {
  .counting-continues {
    font-size: var(--font-size-16xl);
    line-height: 42px;
  }

  .stats-content {
    gap: var(--gap-17xl);
  }
}

@media screen and (max-width: 750px) {
  .counts {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .stats-wrapper-wrapper {
    padding-bottom: var(--padding-26xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .counting-continues {
    font-size: var(--font-size-7xl);
    line-height: 32px;
  }

  .stats-content {
    gap: var(--gap-lg);
  }

  .stats-wrapper {
    padding-top: var(--padding-26xl);
    padding-bottom: var(--padding-26xl);
    box-sizing: border-box;
  }

  .counter-container .counter-subtitle{
    font-size: var(--font-size-sm);
  }
}
.desktop1280x800Child,
.howWeExecute {
  width: 55px;
  height: 55px;
  position: relative;
  display: none;
}

.howWeExecute {
  margin: 0;
  height: 53px;
  width: 367px;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}

.executionHeading {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}

.atOurInterior {
  display: flex;
  justify-content: center;
  align-self: stretch;
  /* position: relative; */
  font-size: var(--font-size-mid-4);
  letter-spacing: 0.01em;
  line-height: 140%;
  text-transform: capitalize;
  font-weight: 500;
  /* text-align: left; */
}

.executionDescription,
.headingContainer {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.headingContainer {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.executionDescription {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 37.7px var(--padding-2xl);
  box-sizing: border-box;
  text-align: center;
}

.emptyCardContent {
  /* align-self: stretch; */
  position: relative;
  letter-spacing: 0.01em;
  line-height: 45px;
  text-transform: capitalize;
}

.cardContentContainer {
  width: 55px;
  border-radius: var(--br-base);
  background-color: var(--color-saddlebrown);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-8xs) var(--padding-lgi);
  box-sizing: border-box;
}

.heading,
.text {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: inherit;
}

.heading {
  font-size: inherit;
  line-height: 29px;
  font-weight: 500;
}

.text {
  font-size: var(--font-size-lgi);
  letter-spacing: 0.01em;
  line-height: 130%;
  text-transform: capitalize;
  font-weight: 400;
}

.card,
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.content {
  align-self: stretch;
  gap: var(--gap-mini-8);
  font-size: var(--font-size-5xl);
  color: var(--color-neutral-black);
}

.card {
  width: 362.4px;
  border-radius: var(--br-base);
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-14xl-7) var(--padding-14xl) 27.7px;
  box-sizing: border-box;
  gap: var(--gap-14xl-7);
  max-width: 100%;
}

.b {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 45px;
  text-transform: capitalize;
  display: inline-block;
  min-width: 19px;
}

.wrapper {
  border-radius: var(--br-base);
  background-color: var(--color-saddlebrown);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-8xs) var(--padding-lg);
}

.heading1,
.text1 {
  align-self: stretch;
  position: relative;
  line-height: 120%;
  font-weight: 500;
}

.text1 {
  margin: 0;
  font-size: var(--font-size-lgi);
  letter-spacing: 0.01em;
  line-height: 130%;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  min-height: 100px;
}

.card1,
.content1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.content1 {
  align-self: stretch;
  gap: var(--gap-mini-8);
  font-size: var(--font-size-6xl-3);
  color: var(--color-neutral-black);
}

.card1 {
  width: 362.4px;
  border-radius: var(--br-base);
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-14xl-7) var(--padding-14xl) 27.7px;
  box-sizing: border-box;
  gap: var(--gap-14xl-7);
  max-width: 100%;
}

.cardsContainer,
.executionCards {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.cardsContainer {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  overflow-x: auto;
  align-items: center;
  gap: var(--gap-13xl);
}

.executionCards {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-4xl) 43.6px;
  box-sizing: border-box;
  font-size: var(--font-size-13xl);
  color: var(--background-color-primary);
}

.getQuote {
  flex: 1;
  position: relative;
  line-height: 150%;
  text-transform: capitalize;
  display: inline-block;
  min-width: 105px;
}

.iconRelume {
  height: 50.3px;
  width: 50.3px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.button,
.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
}

.button { 
  padding: 23px 134px;
  transition: 1s;
  cursor: pointer;
}

.button .getQuote{
  font-size: larger;
}

.button:hover {
  background-color: #462E0A;
  color: white;
  transition: 1s;
}

.buttonWrapper {
  align-self: stretch;
  height: 144.7px;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
  max-width: 100%;
  font-size: 33.5px;
  color: var(--color-saddlebrown);
}

.executionContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 42.3px;
  max-width: 100%;
}

.desktop1280x800,
.execution {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.execution {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-13xl) 127.3px;
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-neutral-black);
  font-family: var(--font-inter);
}

.desktop1280x800 {
  width: 100%;
  /* height: 8136px; */
  /* position: relative; */
  background-color: var(--background-color-primary);
  /* overflow: hidden; */
  flex-direction: column;
  /* padding: 0 0 781.3px; */
  gap: 25px;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1275px) {
  .execution {
    padding-bottom: 54px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1100px) {
  .execution {
    padding-bottom: 35px;
    box-sizing: border-box;
  }

  .desktop1280x800 {
    height: auto;
  }

  .cardsContainer {
    gap: var(--gap-base);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 750px) {
  .howWeExecute {
    font-size: var(--font-size-13xl);
    line-height: 38px;
  }

  .b,
  .emptyCardContent {
    font-size: var(--font-size-7xl);
    line-height: 36px;
  }

  .cardsContainer {
    gap: var(--gap-base);
    display: flex;
    flex-direction: column;
  }

  .getQuote {
    font-size: 27px;
    line-height: 40px;
  }

  .button {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-48xl);
    box-sizing: border-box;
  }

  .buttonWrapper {
    height: auto;
  }

  .executionContent {
    gap: var(--gap-2xl);
  }
}

@media screen and (max-width: 450px) {
  .howWeExecute {
    font-size: var(--font-size-5xl);
    line-height: 29px;
  }

  .emptyCardContent,
  .heading {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }

  .heading {
    line-height: 23px;
  }

  .card {
    gap: var(--gap-mid);
  }

  .b {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }

  .heading1 {
    font-size: var(--font-size-xl);
    line-height: 24px;
  }

  .card1 {
    gap: var(--gap-mid);
  }

  .getQuote {
    font-size: var(--font-size-xl);
    line-height: 30px;
  }

  .button,
  .execution {
    box-sizing: border-box;
  }

  .button {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
  }

  .execution {
    padding-bottom: var(--padding-4xl);
  }
}
footer {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--font-size-sm);
  font-family: var(--font-inter);
  overflow: hidden; /* Ensure content doesn't overflow */
}

footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/public/images/footer-image-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* filter: blur(9px);  */
  z-index: -1; /* Keep the background behind the content */
  /* transform: scale(2.05); */
}

.container{
    backdrop-filter: blur(10px);
    opacity: 0.8;
}


@media screen and (max-width: 768px) {
  .quicklinks{
    flex-direction: column;
  }
}

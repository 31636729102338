.fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-wrap: wrap; /* Wrap buttons on small screens */
    gap: 0.5rem;     /* Spacing between buttons */
  }
  
  .fc-button {
    padding: 0.5rem 1rem;  /* Add padding to buttons */
    font-size: 0.75rem;    /* Smaller font for small screens */
  }
  
  @media (min-width: 768px) {
    .fc-button {
      font-size: 1rem;     /* Larger text for medium+ screens */
      padding: 0.75rem 1.5rem;
    }
  }

  @media (min-width: 475px) {
    .fc-col-header{
        font-size: 12px;
    }
  }
  

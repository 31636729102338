@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Satoshi+Variable:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satoshi+Variable:wght@400;500&display=swap');
/* Import Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/* CSS class for Dancing Script font */
.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* CSS class for Playwrite AU SA Guides font */
.playwrite-au-sa-guides-regular {
  font-family: "Playwrite AU SA Guides", cursive;
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;
  line-height: normal;
  width: 100%;
  /* font-family: 'Poppins', sans-serif; */
}

p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  /* Regular weight */
  /* font-size: 12px; */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}


html,
body {
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For IE and Edge */
}

* {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
}

@font-face {
  font-family: 'Satoshi Variable';
  src: url('../public/images/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('../public/images/fonts/Satoshi-Variable.woff') format('woff');
  font-weight: 100 900;
  /* Adjust if the font has different weights */
  font-display: swap;
  /* Optional: to improve loading performance */
}


@font-face {
  font-family: 'Peristiwa';
  src: url('../public/images/fonts/Peristiwa.otf') format('otf');
  font-weight: 100 900;
  font-display: swap;
}


@media print {
  body {
    margin: 0;
    padding: 0;
    background: white;
    /* Set background to white for printing */
  }

  .print\:hidden {
    display: none !important;
    /* Hide elements with this class when printing */
  }

  .print\:border {
    border: 1px solid #ccc !important;
    /* Apply border to print */
  }

  .print\:rounded-none {
    border-radius: 0 !important;
    /* Remove rounded corners for print */
  }

  .print\:max-w-none {
    max-width: none !important;
    /* Allow full width on print */
  }
}



:root {
  /* fonts */
  --font-archivo: Archivo;
  --text-regular-normal: Roboto;
  --font-inter: Inter;
  --font-archivo-black: "Archivo Black";
  --font-montserrat: Montserrat;
  --font-satoshi-variable: 'Satoshi Variable';

  /* fonts */
  --font-satoshi-variable: "Satoshi Variable";
  --text-regular-normal: Roboto;
  --font-archivo-variable: "Archivo Variable";
  --font-inter: Inter;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-sm: 14px;
  --font-size-xs-9: 11.9px;
  --font-size-8xl-7: 27.7px;
  --font-size-3xl: 22px;
  --font-size-xl: 20px;
  --font-size-sm-1: 13.1px;
  --text-regular-normal-size: 16px;
  --font-size-7xl-1: 26.1px;
  --font-size-2xl: 21px;
  --font-size-smi: 13px;
  --font-size-mini-7: 14.7px;
  --font-size-mid-4: 17.4px;
  --font-size-20xl-1: 39.1px;
  --font-size-4xl: 23px;
  --font-size-12xl: 31px;
  --font-size-7xl-2: 26.2px;
  --font-size-base-3: 16.3px;
  --font-size-21xl: 40px;
  --font-size-5xl: 24px;
  --font-size-13xl: 32px;
  --font-size-lgi: 19px;
  --font-size-6xl-3: 25.3px;
  --font-size-7xl: 26px;
  --font-size-9xl: 28px;
  --font-size-base-4: 15.4px;
  --font-size-15xl-7: 34.7px;
  --font-size-lg: 18px;
  --font-size-mini-2: 14.2px;
  --font-size-27xl-2: 46.2px;
  --font-size-18xl: 37px;
  --font-size-11xl: 30px;
  --font-size-smi-2: 12.2px;
  --font-size-19xl-9: 38.9px;
  --font-size-smi-4: 12.4px;
  --font-size-4xs-4: 8.4px;
  --font-size-lgi-5: 19.5px;




  --text-regular-normal-size: 16px;
  --font-size-2xs-7: 10.7px;
  --font-size-smi-3: 12.3px;
  --font-size-2xs-3: 10.3px;
  --font-size-smi-1: 12.1px;
  --font-size-smi-4: 12.4px;
  --font-size-mini-2: 14.2px;
  --font-size-xl: 20px;
  --font-size-14xl-5: 33.5px;
  --font-size-8xl: 27px;
  --font-size-57xl: 76px;
  --font-size-42xl: 61px;
  --font-size-27xl: 46px;
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-25xl: 44px;
  --font-size-16xl: 35px;
  --font-size-7xl: 26px;
  --font-size-13xl: 32px;
  --font-size-7xl-1: 26.1px;
  --font-size-2xl: 21px;
  --font-size-40xl-7: 59.7px;
  --font-size-29xl: 48px;
  --font-size-17xl: 36px;
  --font-size-11xl-4: 30.4px;
  --font-size-39xl: 58px;

  /* Colors */
  --background-color-primary: #fff;
  --color-gray-100: #2a1f03;
  --color-gray-200: rgba(255, 255, 255, 0.8);
  --color-gray-300: rgba(0, 0, 0, 0.8);
  --color-whitesmoke-100: #f2f2f2;
  --background-color-secondary: #eee;
  --color-whitesmoke-200: rgba(245, 245, 245, 0.8);
  --color-dimgray-100: #565656;
  --color-dimgray-200: #545454;
  --color-neutral-black: #000;
  --color-lightsteelblue: rgba(163, 178, 212, 0.1);


  --white: #fff;
  --text-secondary: #aaa;
  --text-success: #027A48;
  --color-brand-dark-blue: #0d193a;
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --color-gray-200: rgba(0, 0, 0, 0.8);
  --color-whitesmoke-100: #f2f2f2;
  --background-color-secondary: #eee;
  --color-whitesmoke-200: rgba(238, 238, 238, 0.5);
  --color-whitesmoke-300: rgba(245, 245, 245, 0.8);
  --color-dimgray: #565656;
  --color-brand-dark-green: #034930;
  --color-brand-light-blue: #192c4b;
  --color-darkslategray-100: rgba(3, 73, 48, 0.8);
  --color-seagreen-100: #29a16e;
  --color-system-success-green: #027a48;
  --text-primary: #000;
  --color-slategray-100: #404d6e;
  --color-slategray-200: rgba(64, 77, 110, 0.09);
  --background-color-success: #ecfdf3;
  --color-lightgray: rgba(204, 204, 204, 0.5);
  --color-saddlebrown: #462e0a;
  --color-teal: #367d63;
  --color-lightblue: #2e7eb2;
  --color-blue: #084479;
  --color-blueDark: #0D193A;
  --color-silver: #aeafb1;
  --color-green: #369f6a;
  --color-linear-gradient: linear-gradient(135deg, #192C4B 48%, #0D193A 100%)
    /* Gaps */
    --gap-49xl-6: 68.6px;
  --gap-mid: 17px;
  --gap-15xl: 34px;
  --gap-xl: 20px;
  --gap-2xl-3: 21.3px;
  --gap-5xs: 8px;
  --gap-158xl: 177px;
  --gap-69xl: 88px;
  --gap-23xl: 42px;
  --gap-2xl: 21px;
  --gap-12xs: 1px;
  --gap-5xs-5: 7.5px;
  --gap-41xl: 60px;
  --gap-11xl: 30px;
  --gap-2xs-7: 10.7px;
  --gap-9xs-6: 3.6px;
  --gap-3xs: 10px;
  --gap-46xl-1: 65.1px;
  --gap-base: 16px;
  --gap-14xl: 33px;
  --gap-lgi-5: 19.5px;
  --gap-smi: 13px;
  --gap-46xl-4: 65.4px;
  --gap-59xl-5: 78.5px;
  --gap-20xl: 39px;
  --gap-lgi-6: 19.6px;
  --gap-sm-1: 13.1px;
  --gap-20xl-2: 39.2px;
  --gap-lgi: 19px;
  --gap-xs-4: 11.4px;
  --gap-13xl: 32px;
  --gap-14xl-7: 33.7px;
  --gap-mini-8: 14.8px;
  --gap-49xl-5: 68.5px;
  --gap-22xl-2: 41.2px;
  --gap-lg: 18px;
  --gap-6xs-8: 6.8px;
  --gap-43xl-9: 62.9px;
  --gap-12xl: 31px;
  --gap-8xl-4: 27.4px;
  --gap-mid-1: 17.1px;
  --gap-9xs-4: 3.4px;
  --gap-mid-4: 17.4px;
  --gap-44xl: 63px;
  --gap-35xl: 54px;
  --gap-8xl: 27px;
  --gap-xs: 12px;
  --gap-61xl: 80px;
  --gap-21xl: 40px;
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-11xs: 2px;
  --gap-7xs-5: 5.5px;
  --gap-29xl-4: 48.4px;
  --gap-mini-2: 14.2px;
  --gap-5xs-1: 7.1px;
  --gap-9xl-4: 28.4px;
  --gap-33xl: 52px;
  --gap-65xl: 84px --padding-37xl: 56px;
  --padding-11xl: 30px;
  --padding-17xl: 36px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-18xl: 37px;
  --padding-118xl: 137px;
  --padding-18xl-1: 37.1px;
  --padding-xl: 20px;
  --padding-54xl: 73px;
  --padding-5xl: 24px;
  --padding-9xs-1: 3.1px;
  --padding-10xs: 3px;
  --padding-6xs-1: 6.1px;
  --padding-6xs-2: 6.2px;
  --padding-5xl-5: 24.5px;
  --padding-5xl-6: 24.6px;
  --padding-30xl: 49px;
  --padding-2xs-7: 10.7px;
  --padding-11xs: 2px;
  --padding-66xl: 85px;
  --padding-9xl: 28px;
  --padding-9xs-6: 3.6px;
  --padding-9xs-5: 3.5px;
  --padding-5xs-1: 7.1px;
  --padding-5xs-2: 7.2px;
  --padding-12xs-1: 0.1px;
  --padding-9xl-4: 28.4px;
  --padding-9xl-5: 28.5px;
  --padding-6xs: 7px;
  --padding-sm: 14px;
  --padding-223xl-7: 242.7px;
  --padding-139xl: 158px;
  --padding-29xl: 48px;
  --padding-115xl: 134px;
  --padding-48xl: 67px;
  --padding-13xl: 32px;
  --padding-73xl-4: 92.4px;
  --padding-41xl: 60px;
  --padding-20xl: 39px;
  --padding-72xl-2: 91.2px;
  --padding-33xl: 52px;
  --padding-40xl: 59px;
  --padding-7xl: 26px;
  --padding-19xl: 38px;
  --padding-2xs: 11px;
  --padding-2xl: 21px;
  --padding-lgi-5: 19.5px;
  --padding-mini: 15px;
  --padding-base-3: 16.3px;
  --padding-base-2: 16.2px;
  --padding-14xl-7: 33.7px;
  --padding-14xl: 33px;
  --padding-3xl-7: 22.7px;
  --padding-8xs: 5px;
  --padding-3xl: 22px;
  --padding-4xl: 23px;
  --padding-109xl-5: 128.5px;
  --padding-65xl: 84px;
  --padding-7xs-3: 5.3px;
  --padding-base-5: 16.5px;
  --padding-413xl: 432px;
  --padding-125xl: 144px;
  --padding-75xl: 94px;
  --padding-51xl: 70px;
  --padding-50xl: 69px;
  --padding-26xl: 45px;
  --padding-7xs-1: 5.1px;
  --padding-4xl-9: 23.9px;
  --padding-8xs-7: 4.7px;
  --padding-8xs-6: 4.6px;
  --padding-lg: 18px;
  --padding-xs: 12px;
  --padding-27xl: 46px;
  --padding-45xl: 64px;
  --padding-28xl: 47px;
  --padding-10xs-2: 2.2px;
  --padding-39xl-5: 58.5px;
  --padding-11xl-3: 30.3px;
  --padding-102xl: 121px;

  /* Border radiuses */
  --br-5xl: 24px;
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-11xs: 2px;
  --br-base: 16px;
  --br-xs: 12px;
  --br-7xs-2: 5.2px;
  --br-mini-2: 14.2px;
  --br-9xs: 4px;
  --br-4xs-4: 8.4px;
  --br-base-2: 15.2px;

  /* Effects */
  --shadow-large: 0px 20px 24px -4px rgba(0, 0, 0, 0.08),
    0px 8px 8px -4px rgba(0, 0, 0, 0.03);
  --shadow-xxlarge: 0px 32px 64px -12px rgba(0, 0, 0, 0.14);
  --shadow-medium: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
    0px 4px 6px -2px rgba(0, 0, 0, 0.03);
}
.slide-number-labels {
  width: 16px;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 43px;
  text-transform: capitalize;
  display: inline-block;
}
.card-numbering {
  color: white;
  width: 54.8px;
  box-shadow: var(--shadow-xxlarge);
  border-radius: var(--br-base-2);
  background-color: var(--color-brand-dark-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-8xs-6) var(--padding-lg) var(--padding-8xs-7);
  box-sizing: border-box;
}
.iconslogos {
  height: 48px;
  width: 100px;
  position: relative;
  border-radius: 12px;
}
.hitch-hike-project {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;

  line-height: 62px;
  font-weight: 500;
  font-family: inherit;
  display: inline-block;

  max-width: 100%;
}
.project-details {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
  gap: var(--gap-lg);
  max-width: 100%;
}
.check-out-some {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: 0.02em;
  line-height: 140%;
  font-family: var(--font-inter);
  white-space: pre-wrap;
  text-align: justify;
}
.project-info,
.slide-content,
.slide3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}
.project-info {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-lg);
  font-size: var(--font-size-25xl);
}
.slide-content,
.slide3 {
  justify-content: flex-start;
  box-sizing: border-box;
}
.slide-content {

  padding: var(--padding-5xl) 0 var(--padding-4xl-9);
  gap: var(--gap-33xl);
}

.slide3 {
  position: relative; /* Needed for the overlay positioning */
  align-self: stretch;
  border-radius: var(--br-9xs);
  padding: var(--padding-13xl);
  background-image: url(/public/images/slide31@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-11xl-4);
  color: white;
  font-family: var(--font-satoshi-variable);
  z-index: 1; /* Ensures that the text is above the overlay */
}

.slide3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and transparency as needed */
  border-radius: var(--br-9xs); /* Apply the same border-radius */
  z-index: -1; /* Ensures that the overlay is below the text */
}


.btn-viewproject{
  font-size: large;
  border: 1px solid black;
  border-radius: 12px;
  width: 175px;
  position: relative;
  padding-top: 8px;
  height: 45px;
  background-color: #023d62;
  color: white;
  display: flex;
    gap: 15px;
    padding-left: 13px;

}

.btn-viewproject:hover{
  background-color: #0e4729;
}



@media screen and (max-width: 1050px) {
  .slide-number-labels {
    font-size: var(--font-size-5xl);
    line-height: 34px;
  }
  .hitch-hike-project {
    font-size: var(--font-size-16xl);
    line-height: 49px;
  }
}
@media screen and (max-width: 750px) {
  .slide-content {
    gap: var(--gap-7xl);
  }
  .slide3 {
    padding-top: var(--padding-2xl);
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .slide-number-labels {
    font-size: var(--font-size-lg);
    line-height: 26px;
  }
  .hitch-hike-project {
    font-size: var(--font-size-7xl);
    line-height: 37px;
  }
  .slide-content {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .check-out-some{
    font-size: var(--font-size-sm);
    margin-bottom: 10px;
    text-align: justify;
  }
}

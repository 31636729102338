.b {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-40xl-7);
  line-height: 72px;
  font-family: var(--font-satoshi-variable);
}
.project-complete,
.project-complete1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  text-align: center;
}
.project-complete1 {
  align-self: stretch;
  display: none;
}
.count-stats {
  width: 149px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-7xs-1) 0;
  box-sizing: border-box;
  gap: var(--gap-9xs-7);
  text-align: left;
  font-size: var(--font-size-7xl-1);
  color: var(--text-primary);
  font-family: var(--font-inter);
}
@media screen and (max-width: 1050px) {
  .b {
    font-size: var(--font-size-29xl);
    line-height: 57px;
  }
}
@media screen and (max-width: 450px) {
  .b {
    font-size: var(--font-size-17xl);
    line-height: 43px;
  }
  .project-complete,
  .project-complete1 {
    font-size: var(--font-size-2xl);
    line-height: 31px;
  }

  .count-stats{
    gap: 5px;
    
  }
  .project-complete,.project-complete1{
    font-size: var(--font-size-sm);
    text-wrap: nowrap;
  }
  .b{
    font-size: var(--font-size-4xl);
  }
}

.switch {
    position: relative;
    display: inline-block;
    width: 3.5rem; /* Width for better movement space */
    height: 1.75rem; /* Adjusted height */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #28096b; /* Background color */
    transition: background-color 0.4s;
    border-radius: 1.75rem; /* Rounded switch */
}

.slider::before {
    position: absolute;
    content: "";
    height: 1.5rem; /* Size of the sun/moon */
    width: 1.5rem;
    border-radius: 50%; /* Rounded for circular shape */
    left: 0.1rem; /* Start position for the sun */
    bottom: 0.1rem;
    background-color: #fff000; /* Yellow for the sun */
    transition: transform 0.4s, background-color 0.4s, box-shadow 0.4s;
}

input:checked + .slider {
    background-color: #522ba7; /* Night mode background */
}

input:checked + .slider::before {
    transform: translateX(1.8rem); /* Move the circle (sun) to the right */
    background-color: #fff000; /* Keep the yellow color for the moon */
    box-shadow: -10px 0 0 0 #522ba7; /* Crescent moon effect */
}

.b1 {
    position: relative;
    letter-spacing: 0.01em;
    line-height: 45px;
    text-transform: capitalize;
    display: inline-block;
    min-width: 19px;
}

.card-numbering1 {
    border-radius: var(--br-base);
    background-color: var(--color-brand-dark-green);
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-8xs) var(--padding-3xl);
}

.heading1,
.text2 {
    align-self: stretch;
    position: relative;
}

.heading1 {
    margin: 0;
    font-size: inherit;
    letter-spacing: 0.01em;
    line-height: 34px;
    font-weight: 700;
    font-family: inherit;
}

.text2 {
    height: 100px;
    font-size: small;
    text-align: justify;
    letter-spacing: 0.02em;
    line-height: 140%;
    font-family: var(--font-inter);
    display: inline-block;
}

.card,
.content24 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content24 {
    align-self: stretch;
    gap: var(--gap-mini-8);
    font-size: var(--font-size-5xl);
    color: var(--text-primary);
}

.card {
    flex: 1;
    border-radius: var(--br-base);
    background-color: var(--background-color-success);
    /* background-color: orange; */
    overflow: hidden;
    padding: var(--padding-14xl-7) var(--padding-14xl) var(--padding-3xl-7);
    box-sizing: border-box;
    gap: var(--gap-14xl-7);
    min-width: 271px;
    max-width: 100%;
    text-align: left;
    font-size: var(--font-size-13xl);
    color: var(--white);
    font-family: var(--font-satoshi-variable);
}

@media screen and (max-width: 1050px) {
    .b1 {
        font-size: var(--font-size-7xl);
        line-height: 36px;
    }
}

@media screen and (max-width: 450px) {

    .b1,
    .heading1 {
        font-size: var(--font-size-lgi);
        line-height: 27px;
    }

    .card {
        gap: var(--gap-mid);
    }
}